/**
 * 我的账户接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法

 const account = {
    // remaining(params) {
    //     return get(`${base.bd}/boxUser/getCode`, params);
    // },
    // 获取余额
    getBalance(params) {
        return post(`${base.bd}/user_account/MyAccount`, params);
    },
    // 安全设置
    securitySettings(params) {
        return post(`${base.bd}/user_account/SecuritySet`, params);
    },
    // 实名、支付密码设置接口
    SecuritySetNew(params) {
        return post(`${base.bd}/user_account/SecuritySetNew`, params);
    },
    // 判断支付密码是否设置接口type1判断是否实名绑定接口type2
    isPaymentCode(params) {
        return post(`${base.bd}/user_account/IsSecuritySet`, params);
    },
    // 上传头像跟昵称
    updateBasicInformation(params) {
        return post(`${base.bd}/user_account/updateBasicInformation`, params,{headers:{"Content-Type":"multipart/form-data"}},'noqs');
    },
    // 获取基础资料
    BasicInformation(params) {
        return post(`${base.bd}/user_account/BasicInformation`,params);
    },

    // 我的足迹
    myFootprint(params) {
        return post(`${base.bd}/user_account/MyFootprint`, params);
    },
    // 成交动态
    accountTransaction(params) {
        return post(`${base.bd}/user_account/AccountTransaction`, params);
    },
    // 钱包收支
    walletBill(params) {
        return post(`${base.bd}/user_account/WalletBill`, params);
    },
    // 我的交易
    trade(params) {
        return post(`${base.bd}/user_account/AccountTransaction`, params);
    },
    // 我的交易商品操作
    goodsOperate(params) {
        return post(`${base.bd}/user_account/GoodsOperate`, params);
    },
    // 提现
    withdraw(params) {
        return post(`${base.bd}/Transfer/apply`, params);
    },
    // 客服信息
    getService(params) {
        return post(`${base.bd}/user_account/CustomerService`, params);
    },
 };

 export default account;