/** 
 * api接口的统一出口
 */
// 登录模块接口
import login from '@/api/login';
// 注册模块接口
import register from '@/api/register';
// 我的账户模块接口
import account from '@/api/myaccount';
// 账号助手模块接口
import helper from '@/api/helper';
// 我要购买模块接口
import shopping from '@/api/shopping';
// 站内信
import message from '@/api/messages';
// 我要出售模块接口
import sale from '@/api/sale';
//组合购买仙玉接口
import pay from '@/api/pay';

// 导出接口
export default {
    login,
    register,
    account,
    helper,
    shopping,
    message,
    sale,
    pay
}
