import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/public.css'
import api from './api/index'; //导入api接口
import { Base64 } from '@/utils/base64'
import global from '@/utils/global'
import bus from '@/utils/bus'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.$httpRequestList = [];
Vue.prototype.clearHttpRequestingList = function () {
  if (Vue.$httpRequestList.length > 0) {
    Vue.$httpRequestList.forEach((item) => {
      item()
    })
    Vue.$httpRequestList = []
  }
}

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$Base64 = Base64;
Vue.prototype.$global = global;
Vue.prototype.$bus = bus;


const vm =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
