import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
function GetQueryString(name){
    let reg = new  RegExp( "(^|&)" + name + "=([^&]*)(&|$)" );
    let r = window.location.search.substr(1).match(reg);
    if (r!= null ) return   unescape(r[2]); return  null ;
}
const routes = [
  {
    path: '/',
    name: '',
    meta:{requireAuth:true},
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        meta:{
            tab:'Home',
            requireAuth:true
        },
        component: () => import('../views/home/home.vue')
      },
      {
        path: '/sale',
        name: 'Sale',
        meta:{
            tab:'Sale',
            requireAuth:true
        },
        component: () => import('../views/sale/Sale.vue'),
        children: [
          {
            meta:{
                tab:'Sale',
                requireAuth:true
            },
            path: '/sale',
            name: 'Salerole',
            component: () => import('../views/sale/Salerole.vue')
          },{
            meta:{
              tab:'Sale',
              requireAuth:true
          },
          path: '/sale/saleitems',
          name: 'Saleitems',
          component: () => import('../views/sale/Saleitems.vue')
          }
        ]
      },
      {
        path: '/account',
        name: 'Account',
        meta:{
            tab:'Account',
            requireAuth:true
        },
        component: () => import('../views/Account.vue')
      },
      {
        path: '/helper',
        name: 'Helper',
        meta:{
            tab:'trackit',
            requireAuth:false
        },
        component: () => import('../views/helper/Helper.vue'),
        children:[
          {
            meta:{
                tab:'trackit',
                requireAuth:false
            },
            path: '/helper',
            name: 'trackit',
            component: () => import('../views/helper/Trackit.vue')
          },
          {
            meta:{
                tab:'trackit',
                requireAuth:false
            },
            path: '/helper/appeal',
            name: 'Appeal',
            component: () => import('../views/helper/Appeal.vue')
          },
          {
            meta:{
                tab:'trackit',
                requireAuth:false
            },
            path: '/helper/remowepassword',
            name: 'Remowepassword',
            component: () => import('../views/helper/Removepassword.vue')
          }
        ]
      }
    ]
  },
  {
    path:'/xianYuOrder',
    name:'XianYuOrder',
    meta:{requireAuth:false},
    component:()=>import('../views/home/xianYuOrder.vue')
  },
  {
    path: '/buytel',
    name: 'Buytel',
    meta:{requireAuth:false},
    component: () => import('../views/home/buyTel.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{requireAuth:false},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta:{requireAuth:false},
    component: () => import('../views/Register.vue')
  },
  {
    path: '/findpassword',
    name: 'Findpassword',
    meta:{requireAuth:false},
    component: () => import('../views/Findpassword.vue')
  },
  {
    path: '/moregame',
    name: 'moreGame',
    meta:{requireAuth:false},
    component: () => import('../views/moreGame.vue')
  },
  {
    path: '/characterdetails',
    name: 'characterDetails',
    meta:{requireAuth:false},
    component: () => import('../views/orderForm/characterDetails.vue')
  },
  {
    path: '/paymentpage',
    name: 'paymentPage',
    meta:{requireAuth:true},
    component: () => import('../views/orderForm/paymentPage.vue')
  },
  {
    path: '/safesetting',
    name: 'SafeSetting',
    meta:{requireAuth:true},
    component: () => import('../views/account/SafeSetting.vue')
  },
  {
    path: '/paymentchannels',
    name: 'paymentChannels',
    meta:{requireAuth:true},
    component: () => import('../views/orderForm/paymentChannels.vue')
  },
  {
    path: '/footprint',
    name: 'Footprint',
    meta:{requireAuth:true},
    component: () => import('../views/account/Footprint.vue')
  },
  {
    path: '/wallet',
    name: 'Wallet',
    meta:{requireAuth:true},
    component: () => import('../views/account/Wallet.vue')
  },
  {
    path: '/deallog',
    name: 'Deallog',
    meta:{requireAuth:true},
    component: () => import('../views/account/Deallog.vue')
  },
  {
    path: '/trade',
    name: 'Trade',
    meta:{requireAuth:true},
    component: () => import('../views/trade/Trade.vue'),
    children: [
      {
        path: '/trade',
        name: 'Emptiontrade',
        meta:{requireAuth:true},
        component: () => import('../views/trade/Emptiontrade.vue')
      },
      {
        path: '/trade/saletrade',
        name: 'Saletrade',
        meta:{requireAuth:true},
        component: () => import('../views/trade/Saletrade.vue')
      },
      // {
      //   path: '/trade/emptiontrade',
      //   name: 'Emptiontrade',
      //   meta:{requireAuth:true},
      //   component: () => import('../views/trade/Emptiontrade.vue')
      // },
    ]
  },
  {
    path: '/property',
    name: 'property',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/property.vue')
  },
  {
    path: '/role',
    name: 'role',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/role.vue')
  },
  {
    path: '/knapsack',
    name: 'knapsack',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/knapsack.vue')
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/wareHouse.vue')
  },
  {
    path: '/activityWareHouse',
    name: 'activityWareHouse',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/activityWareHouse.vue')
  },
  {
    path: '/treasureWareHouse',
    name: 'treasureWareHouse',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/treasureWareHouse.vue')
  },
  {
    path: '/figure',
    name: 'figure',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/figure.vue')
  },
  {
    path: '/service',
    name: 'Service',
    meta:{requireAuth:false},
    component: () => import('../views/account/Service.vue')
  },
  {
    path: '/modelclothes',
    name: 'modelclothes',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/Modelclothes.vue')
  },
  {
    path: '/rule',
    name: 'Rule',
    meta:{requireAuth:false},
    component: () => import('../views/account/Rule.vue')
  },
  {
    path: '/spiritpet',
    name: 'spiritpet',
    meta:{requireAuth:false},
    component: () => import('../views/detailedProperties/Spiritpet.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    meta:{requireAuth:true},
    component: () => import('../views/account/Setting.vue')
  },
  {
    path: '/datamodify',
    name: 'Datamodify',
    meta:{requireAuth:true},
    component: () => import('../views/account/Datamodify.vue')
  },
  {
    path: '/passwordset',
    name: 'Passwordset',
    meta:{requireAuth:true},
    component: () => import('../views/account/Passwordset.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    meta:{requireAuth:false},
    component: () => import('../views/account/Withdraw.vue')
  },
  {
    path: '/accountdesp',
    name: 'Accountdesp',
    meta:{requireAuth:false},
    component: () => import('../views/Accountdesp.vue')
  },
  {
    path: '/retrieveTrackit',
    name: 'RetrieveTrackit',
    meta:{
        tab:'trackit',
        requireAuth:true
    },
    component: () => import('../views/helper/RetrieveTrackit.vue')
  },
  {
    path: '/appealRecord',
    name: 'Appealrecord',
    meta:{
        tab:'trackit',
        requireAuth:true
    },
    component: () => import('../views/helper/Appealrecord.vue')
  },
  {
    path: '/deletionrecord',
    name: 'Deletionrecord',
    meta:{
        tab:'trackit',
        requireAuth:true
    },
    component: () => import('../views/helper/Deletionrecord.vue')
  },
  {
    path: '/message',
    name: 'Message',
    meta:{requireAuth:true},
    component: () => import('../views/message/Message.vue')
  },
  {
    path: '/messagedetail',
    name: 'Messagedetail',
    meta:{requireAuth:true},
    component: () => import('../views/message/Messagedetail.vue')
  },
  {
    path: '/paysuccess',
    name: 'Paysuccess',
    meta:{requireAuth:true},
    component: () => import('../views/orderForm/Paysuccess.vue')
  },
  {
    path: '/dicker',
    name: 'Dicker',
    meta:{requireAuth:true},
    component: () => import('../views/orderForm/Dicker.vue')
  },
  {
    path: '/counteroffer',
    name: 'Counteroffer',
    meta:{requireAuth:false},
    component: () => import('../views/account/Counteroffer.vue')
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/dist',
  routes
})
//console.log(process.env.BASE_URL);

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  // console.log(token);
  let str = GetQueryString("a")
  if (str) {
    sessionStorage.setItem('title',str)
    sessionStorage.setItem('comePath',window.location.href.split('?')[0])
  //  console.log(window.location.href.split('?')[0]);
    localStorage.clear()
    token=false
  }
  if(token){
    // 已登录
    next();
  }else{
    // 未登录
      // 拦截
      // if (to.name=='Login') {
      //   next();
      // }else{
      //   next({
      //     name: 'Login'
      //   });
      // }
       if(to.meta.requireAuth){
        next({name: 'Login'});
      }else{
        // 不拦截
        next();
      }
  }
})

export default router
