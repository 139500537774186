/**
 * login模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法
 
 const login = {
    loginIn(params) {
        return post(`${base.bd}/boxUser/login`, params);
    },
    loginSdk(params) {
        return post(`${base.bd}/boxUser/login_sdk`, params);
    },
    loginByCode(params) {
        return post(`${base.bd}/boxUser/loginByCode`, params);
    },
    resetPassword(params) {
        return post(`${base.bd}/boxUser/resetPassword`, params);
    },
    //退出登录
    loginOut(params) {
        return post(`${base.bd}/boxUser/logout`, params);
    },
 };
 
 export default login;