/**
 * 我要出售模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法

 const sale = {
    // 申请上架
    onSale(params) {
        return post(`${base.bd}/goods/create`, params);
    },
    //交易规则
    getRule(params) {
        return post(`${base.bd}/user_account/TransactionRule`, params);
    },
    //获取背包仓库物品
    getBagItems(params) {
        return post(`${base.bd}/server/getEquipmentList`, params);
    },
    //物品详情
    getItemDetail(params) {
        return post(`${base.bd}/server/getBagDetail`, params);
    },
    //我要出售物品
    createEquipment(params) {
        return post(`${base.bd}/goods/createEquipment`, params);
    },
    //获取交易比
    newest_currency_order(params) {
        return post(`${base.bd}/order/newest_currency_order`, params);
    },
    //官方建议比例
    getProportion(params) {
        return get(`${base.bd}/goods/getProportion`, params);
    }
 };

 export default sale;