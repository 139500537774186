/**
 * 我要购买模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法

 const shopping = {
    // 游戏渠道列表
    gameChannelList(params) {
        return post(`${base.bd}/shopping_hall/Gamelist`, params);
    },
    // 根据渠道获取大区列表
    regionList(params) {
        return post(`${base.bd}/shopping_hall/Regiones`, params);
    },
    // 根据渠道和大区获取区服
    serverList(params) {
        return post(`${base.bd}/shopping_hall/Servers`, params);
    },
    // 商品分类列表
    GoodsType(params) {
        return post(`${base.bd}/shopping_hall/GoodsType`, params);
    },
    // 商品筛选框渲染
    FilterInfo(params) {
        return get(`${base.bd}/shopping_hall/FilterInfo`, params);
        // return post(`${base.bd}/shopping_hall/FilterInfo`, params);
    },
    // 购买大厅列表
    goodsLists(params) {
        return post(`${base.bd}/shopping_hall/GoodsLists`, params);
    },
    getSingleOrderSwitch(params) {
        return post(`${base.bd}/shopping_hall/getSingleOrderSwitch`, params);
    },
    // 商品详情
    goodsDetails(params) {
        return post(`${base.bd}/shopping_hall/CommodityDetail`, params);
    },
    // 穿戴装备/时装
    equipment(params) {
        return post(`${base.bd}/player/playerEquipmentApi`, params);
    },
    // 时装详情
    dressDetails(params) {
        return post(`${base.bd}/player/GoodsDetail`, params);
    },
    // 获取大厅默认筛选项
    getInitData(params) {
        return post(`${base.bd}/shopping_hall/ScreenKey`, params);
    },
    // 获取龙纹接口
    getLongwen(params) {
        return post(`${base.bd}/playerAttach/getLongwen`, params);
    },
    // 状态/属性
    attribute(params) {
        //return post(`${base.bd}/player/attribute`, params);
        return get(`http://192.168.2.111:18403/player/test`, params);
    },
    // 角色加成
    getAttach(params) {
        return post(`${base.bd}/playerAttach/getAttach`, params);
    },
    // 获取灵宠详细信息
    petData(params) {
        return post(`${base.bd}/playerPet/petData`, params);
    },
    //立即支付-支付宝
    createOrderAli(params) {
        return get(`${base.pay}/Pay/createOrder`, params);
    },
    //立即支付-微信
    createOrderWx(params) {
        return get(`${base.pay}/Pay/createOrder`, params);
    },
    //获取支付密码
    checkPayPassword(params) {
        return post(`${base.bd}/order/checkPayPw`, params);
    },
    //公告
    getNotice(params) {
        return post(`${base.bd}/shopping_hall/BoxNotice`, params);
    },
    //判断余额是否够用
    ableAllPay(params) {
        return post(`${base.bd}/wxPay/ableAllPay`, params);
    },
    //判断支付是否成功
    checkOrder(params) {
        return post(`${base.bd}/order/checkOrder`, params);
    },
    //点击游戏头像
    promote(params) {
        return post(`${base.bd}/promote/add`, params);
    },
    //获取灵宠列表
    getPetList(params) {
        return post(`${base.bd}/playerPet/getPetList`, params);
    },
    //获取灵兽信息
    getDataFromIndex(params) {
        return post(`${base.bd}/playerPet/getDataFromIndex`, params);
    },
    //货币比例数量列表
    getScaleGoods(params) {
        return post(`${base.bd}/shopping_hall/scale_goods_count`, params);
    },
    //上架比例列表
    scale_list(params) {
        return post(`${base.bd}/shopping_hall/scale_list`, params);
    },
    //首页是否合区展示
    is_merge_server(params) {
        return post(`${base.bd}/shopping_hall/is_merge_server`, params);
    },
    //仙玉规则
    config_buy_coin(params){
        return post(`${base.bd}/shopping_hall/config_buy_coin`, params); 
    }
 };

 export default shopping;
