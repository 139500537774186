/**
 * login模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法

 const register = {
    //获取验证码
    getCode(params) {
        return get(`${base.bd}/boxUser/getCode`, params);
    },
    register(params) {
        return post(`${base.bd}/boxUser/create`, params);
    },
    //验证验证码
    checkCode(params) {
        return get(`${base.bd}/boxUser/checkCode`, params);
    },
    //验证验证码
    getCode2(params) {
        return get(`${base.bd}/goods/getCode`, params);
    },
 };

 export default register;