<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
      <router-link to="/login">Login</router-link>
    </div> -->
    <keep-alive :include="str">
      <router-view/>
    </keep-alive>
    <!-- <keep-alive :exclude="str" :max="3"> 
      <router-view/>
    </keep-alive> -->
  </div>
</template>
<script>
export default {
  name:'App',
  data(){
    return {
      str:['Messagedetail','homePage'],
      url: 'ws://'+ process.env.VUE_APP_URL.substring(7) +'/message',
      ws: '',
      timeout: 49 * 1000,
      startTimer: null,
      resetTimer: null,
      resetCount: 0,
      isReset: true,
      isdot: false
    }
  },
  
  created() {

    
    // if(this.$route.query.a){
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('username');
    // }
   
    // if (localStorage.getItem('token')) {
    //   this.localSocket();
    // }
  },
  methods: {

    // localSocket() {
    //   let that = this;
    //   if ("WebSocket" in window) {
    //       // console.log("您的浏览器支持 WebSocket!");
    //       if (that.ws.readyState != 1) {
    //         that.ws = new WebSocket(that.url);
    //         that.$global.setWs(that.ws);
    //       }
    //       //ws连接成功后回调函数
    //       that.ws.onopen = function () {
    //         console.log('websocket连接成功', that.$global.ws);
    //         that.initConnection();
    //         that.heartBeat();
    //         that.start();
    //       };
    //       //ws接收信息
    //       that.ws.onmessage = function (res) {
    //         //console.log(res, 1111);
    //         if (res.data.includes('cmd')) {
    //           let data = JSON.parse(res.data);
    //           switch (data.cmd) {
    //             case 'S2C_init': 
    //               if (data.data.code == 0) {
    //                 that.resetCount = 0;
    //                 clearInterval(that.resetTimer);
    //               }
    //               break;
    //             case 'S2C_ping':
    //               that.$global.count = 0;
    //               break;
    //             case 'S2C_newMsg':
    //               //有新消息站内信小红点显示
    //               that.$bus.$emit('isdot', true);
    //               break;
    //             default:
    //               //console.log(data);
    //               //退出登录
    //               if(that.ws.readyState == 1){
    //                 that.isReset = false;
    //                 that.ws.close();
    //                 clearInterval(that.$global.timerN);
    //                 localStorage.removeItem('token');
    //                 localStorage.removeItem('username');
    //                 that.$router.push('/login');
    //               }
    //           }
    //         }
    //       };
    //       //ws关闭后回调函数
    //       that.ws.onclose = function () {
    //         // 关闭 websocket
    //         console.log("连接已关闭...");
    //         //断线重新连接
    //         if (that.isReset) {
    //           that.resetInit();
    //         }
    //       };
    //       //ws报错时的回调函数
    //       that.ws.onerror = function () {
    //         // 关闭 websocket
    //         console.log("连接报错...");
    //         //断线重新连接
    //         that.ws.close();
    //         if (that.isReset) {
    //           that.resetInit();
    //         }
    //       };

    //   } else {
    //       // 浏览器不支持 WebSocket
    //       console.log("您的浏览器不支持 WebSocket!");
    //   }
    // },
    //ws发送连接请求
    // initConnection() {
    //   if (localStorage.getItem('token')) {
    //       let msg = {
    //           cmd: 'C2S_init',
    //           data: {
    //             token: localStorage.getItem('token').substring(7)
    //           }
    //       };
    //       this.ws.send(JSON.stringify(msg));
    //   } else {
    //     this.ws.close();
    //   }
    // },
    //设置定时器重连，重连失败3次退出登录
    // resetInit() {
    //   let that = this;
    //   clearInterval(this.resetTimer);
    //   that.resetCount = 0;
    //   //console.log(that.$global, that.ws, 666666);
    //   if (localStorage.getItem('token') && that.ws.readyState != 1 && that.isReset) {
    //     this.resetTimer = setInterval(() => {
    //       //没连接上会一直重连，设置延迟避免请求过多 
    //       if (that.resetCount < 3) {
    //         that.resetCount ++;
    //         that.localSocket();
    //       } else {
    //         //退出登录
    //         that.isReset = false;
    //         clearInterval(that.resetTimer);
    //         clearInterval(this.$global.timerN);
    //         // that.$api.login.logOut().then();
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('username');
    //         that.$router.push('/login');
    //       }
    //     }, 3000);
    //   }
    // },
    //ws心跳包
    // heartBeat() {
    //   let that = this;
    //   var heartTimer;
    //   heartTimer = setInterval(() => {
    //       if (localStorage.getItem('token') && that.ws.readyState == 1) {
    //           let msg = {
    //               cmd:'C2S_ping',
    //               data:'ping'
    //           }
    //           this.ws.send(JSON.stringify(msg));
    //       } else {
    //           clearInterval(heartTimer);
    //           this.ws.close();
    //       }
    //   }, 50*1000);
    // },
    //心跳检测时长
    // start() {
    //     let that = this;
    //     this.$global.count = 0;
    //     clearInterval(this.startTimer);
    //     this.startTimer = setInterval(() => {
    //         if (localStorage.getItem('token')) {
    //             if (that.$global.count < 3) {
    //                 that.$global.count ++;
    //             } else {
    //                 that.$global.count = 0;
    //                 clearInterval(that.startTimer);
    //                 that.ws.close();
    //             }
    //         } else {
    //             clearInterval(that.startTimer);
    //             that.ws.close();
    //         }
    //     }, this.timeout);
    // }
  },
  watch: {
      $route: {
          handler(to,from) {
            // console.log(to, from);
            if(from){
              // console.log(to.name,from.name)
              // this.str = ['characterDetails','MessageDetail','Message','property','Spiritpet']
              this.str = ['home','homePage']
              if(to.name=='Home'&&from.name=='XianYuOrder'){
                let divForm = document.getElementsByTagName('divform');
                let divnewForm = document.getElementsByTagName('divnewform');
                // console.log(divnewForm)
                if (divnewForm.length || divForm.length) {
                    document.body.removeChild(divnewForm[0]);
                    document.body.removeChild(divForm[0]);
                }
              }
              if(to.name=='Home'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
                let divForm = document.getElementsByTagName('divform');
                let divnewForm = document.getElementsByTagName('divnewform');
                // console.log(divnewForm)
                if (divnewForm.length||divForm.length) {
                    document.body.removeChild(divnewForm[0]);
                    document.body.removeChild(divForm[0]);
                }
              }else if(to.name=='All'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
              }else if(to.name=='Message'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
              }else if(to.name=='Saletrade'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
              }else if(to.name=='Emptiontrade'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
              }else if(to.name=='Footprint'&&from.name=='characterDetails'){
                // this.str = ['characterDetails','MessageDetail'];
              }else{
                // this.str = ['MessageDetail','Message','property','Spiritpet'];
                this.str = ['characterDetails','home','homePage'];
              }
              if (from.name == 'paymentChannels') {
                // localStorage.removeItem('isPay');
              }
              if (to.name != 'Home' && from.name == null && window.history.length <= 2) {
                localStorage.setItem('isRouter', true);
              }else{
                localStorage.setItem('isRouter', false);
              }
              if (from.name == 'Login' && localStorage.getItem('token')) {
                // this.localSocket();
              }
            }
          },
          deep:true, 
          immediate: true
      }
 }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
