/**
 * 组合购买仙玉接口
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法
 
 const pay = {
     //锁为0时获取匹配商品列表
    combinationPay(params) {
        return get(`${base.pay}/shopping_hall/combinationPay`, params);
    },
    // 取消去支付
    cancelCombine(params) {
        return post(`${base.pay}/Pay/cancelCombine`, params);
    },
     // 余额支付
     createOrder(params) {
        return post(`${base.pay}/pay/createOrder`, params);
    },
    //检查组合订单是否被锁定  is_lock	int	0未锁定 1已锁定
    checkOrders(params) {
        return post(`${base.pay}/shopping_hall/checkOrders`, params);
    },
    //  /shopping_hall/configCombinationPay   numTop
    configCombinationPay(params) {
        return get(`${base.pay}/shopping_hall/configCombinationPay`, params);
    },
   
 };
 
 export default pay;