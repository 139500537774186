/**
 * 站内信模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法
 
 const message = {
    //  获取消息列表
    getList(params) {
        return post(`${base.bd}/message/getList`, params);
    },
    // 添加消息列表
    addMessage(params) {
        return post(`${base.bd}/message/addMessage`, params);
    },
    // 消息详情
    getDetail(params) {
        return post(`${base.bd}/message/getDetail`, params);
    },
    // 消息标记全读
    allRead(params) {
        return post(`${base.bd}/message/allRead`, params);
    },
    // 删除消息
    delete(params) {
        return post(`${base.bd}/message/delete`, params);
    },

 };
 
 export default message;