/**
 * 账号助手模块接口列表
 */
 import base from '@/api/base'; //导入接口域名列表
 import { get, post } from '@/utils/request'; //引入请求方法
 
 const helper = {
    
    // 游戏版本
    getChannel(params) {
        return get(`${base.bd}/server/getChannel`, params);
    },
    // 区服列表
    getServerList(params) {
        return get(`${base.bd}/server/getServerList`, params);
    },
    // 选择角色
    getPlayerList(params) {
        return get(`${base.bd}/server/getPlayerList`, params);
    },
    // 找回物品列表
    itemRecoveryList(params) {
        return post(`${base.bd}/itemRecovery/itemRecoveryList`, params);
    },
    // 取消申请、删除记录、再次申请
    editItemRecovery(params) {
        return post(`${base.bd}/itemRecovery/editItemRecovery`, params);
    },
    // 物品找回类型
    itemRecoveryTypeList(params) {
        return post(`${base.bd}/itemRecovery/itemRecoveryTypeList`, params);
    },
    // 根据物品名称获取物品列表
    seachgoods(params) {
        return post(`${base.bd}/itemRecovery/seachgoods`, params);
    },
    // 添加物品找回
    addItemRecovery(params) {
        return post(`${base.bd}/itemRecovery/addItemRecovery`, params);
    },
    // 账号申诉
    addAccountAppeal(params) {
        return post(`${base.bd}/accountAppeal/addAccountAppeal`, params);
    },
    // 账号申诉列表
    accountAppealList(params) {
        return post(`${base.bd}/accountAppeal/accountAppealList`, params);
    },
    // 账号申诉取消申请、删除记录
    editAccountAppeal(params) {
        return post(`${base.bd}/accountAppeal/editAccountAppeal`, params);
    },
    // 删除二级密码
    addSecondPassword(params) {
        return post(`${base.bd}/secondPassword/addSecondPassword`, params);
    },
    // 删除二级密码列表
    secondPasswordList(params) {
        return post(`${base.bd}/secondPassword/secondPasswordList`, params);
    },
    // 删除记录
    delSecondPasswordInfo(params) {
        return post(`${base.bd}/secondPassword/delSecondPasswordInfo`, params);
    }
 };
 
 export default helper;