/**
 * 接口域名的管理
 */
 const base = {    
    bd: process.env.VUE_APP_URL,
    pay: process.env.VUE_APP_PAY,
    newLink: process.env.VUE_APP_NEW_BOX,  
    // bd: 'https://testht4.guanying.xin',
    // cs:'https://testht4.guanying.xin',
    // lj:'https://testht4.guanying.xin',
    // tlr:'https://testht4.guanying.xin'
}
export default base;