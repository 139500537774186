/**axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import axios from 'axios'; //引入axios
 import QS from 'qs'; //引入qs模块，用来序列化post类型的数据
 import { Toast  } from 'vant'; //引入提示框组件
 import router from '../router'; //引入提示框组件
 import Vue from 'vue'
 
 //请求超时时间
//  axios.defaults.timeout = 8000;
 //post请求头
 axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
 
 /**
  * 提示函数
  * 显示1.5秒后关闭
  */
 const tip = msg => {
    Toast ({
         message: msg,
         duration: 1500
     });
 }
 /**
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
 const toLogin = () => {
     router.replace({
         path: '/login',
         query: {
             redirect: router.currentRoute.fullPath
         }
     });
 }
 /**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
 const errorHandle = (status, other) => {
    //  console.log(status);
     //状态码判断
     switch (status) {
         //401: 未登录状态，跳转登录页
         case 401:
            tip('登录过期，请重新登录');
            //store.commit(types.LOGOUT);
            // 清除token
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            setTimeout(() => {
                toLogin();
            }, 1000);
            //  console.log(status);
            //  toLogin();
             break;
         //403: token过期
         //对用户进行提示, 清除token并跳转登录页
         case 403:
             tip('登录过期，请重新登录');
             //store.commit(types.LOGOUT);
             // 清除token
             localStorage.removeItem('token');
             localStorage.removeItem('username');
             setTimeout(() => {
                 toLogin();
             }, 1000);
             break;
         //404: 请求不存在
         case 404:
             tip('请求的资源不存在');
             break;
         default:
             console.log(other);
     }
 }
 /**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
 axios.interceptors.request.use(
     config => {
         //登录流程控制中，根据本地是否存在token判断用户的登录情况
         //如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
         //即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
         const token = localStorage.getItem('token');
         //console.log(token);
         token && (config.headers.Authorization = token);

        return config;
     },
     error => {
         return Promise.reject(error);
     }
 );
 //响应拦截器
 axios.interceptors.response.use(
     //请求成功
    //  response => response.status === 200 ? Promise.resolve(response) : Promise.reject(response),
    response => {
        // console.log(response);
        if (response.status === 200) {
            if ((response.data.code || response.data.code == 0) && response.data.code != 200) {
                if (response.data.code == 401) {
                    tip('登录过期，请重新登录');
                    // alert(response.config.url)
                    // 清除token
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    setTimeout(() => {
                        toLogin();
                    }, 1000);
                }else{
                    tip(response.data.msg);
                }
            }
            return Promise.resolve(response);
        }else{
            return Promise.reject(response);
        }
    },
     //请求失败
     error => {
         const { response } = error;
         if (response) {
             //请求已发出，但是不在2xx的范围
             errorHandle(response.status, response.data.message);
             return Promise.reject(response);
         } else {
             //处理断网的情况
             //eg:请求超时或断网时，更新state的network状态
             console.log(error);
         }
     }
 );
 
 /**
  * 封装get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
 export function get(url, params = {}){
    const CancelToken = axios.CancelToken;
     return new Promise((resolve, reject) =>{
         axios.get(url, {
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                Vue.$httpRequestList.push(c)
            })
         })
         .then(res => {
             resolve(res.data);
         })
         .catch(err => {
             reject(err);
         });
        // axios({
        //     methods: 'get',
        //     url: url,
        //     params: params
        // })
        // .then(res => {
        //     resolve(res.data);
        // })
        // .catch(err => {
        //     reject(err);
        // });
     });
 }
 /**
  * 封装post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
let configs = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
}
 export function post(url, params = {},head = configs,isQs) {
    if(isQs!='noqs'){
        params = QS.stringify(params)
    }
    const CancelToken = axios.CancelToken;
     return new Promise((resolve, reject) => {
         axios.post(url,params,head,{
            cancelToken: new CancelToken(function executor(c) {
                Vue.$httpRequestList.push(c)
            })
        })
         .then(res => {
             resolve(res.data);
         })
         .catch(err => {
             reject(err);
         })
     });
 }
 
 